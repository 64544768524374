import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    padding: '20px 40px',
  },

  header: {
    flex: 1,
    marginBottom: '20px',
  },

  title_header: {
    color: "#535758",
    fontWeight: 500,
    fontSize: 'calc(24px + (8/1200) * 100 * 1vw)'
  },

  balance: {
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "8px",
    marginBottom: "15px",
    backgroundColor: "#fff",
    display: "flex",
    textAlign: "center",

    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    }
  },

  account_information: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 10px",
    borderRadius: "8px",
    color: "#fff",
    backgroundColor: "#88bd3f",

    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },

    "& span": {
      fontWeight: "bold",
    }
  },

  balance_value: {
    flexGrow: "1",
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    color: "#505367",

    "& span": {
      fontWeight: "bold",
      fontSize: 'calc(22px + (34 - 22) * ((100vw - 300px) / (1600 - 300)))',

      "& small": {
        fontSize: "15px",
        marginRight: "8px"

      }
    },

    "& p": {
      fontWeight: "bold",
      fontSize: 'calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)))',
    }
  },

  available_text: {
    color: "#88bd3f",
  },

  button_withdrawl: {
    width: "160px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: "#00619e",
    color: "#fff",
    cursor: "pointer",
    fontSize: '12px',

    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },

    "&:hover": {
      opacity: "0.8",
    },

    "&:disabled": {
      backgroundColor: "grey",
      cursor: "not-allowed",

      "&:hover": {
        opacity: "1",
      },
    }
  },

  transaction_type: {
    display: "flex",
  },

  table: {
    maxHeight: 'calc(100vh - 270px)',
  },

  disablePlan: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '20px',
  },

  disableTitle: {
    marginBottom: '20px',
  },

  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bgStatusError: {
    color: '#EC5350',
    fontWeight: 'bold',
  },

  bgStatusPrimary: {
    color: '#2E7C33',
    fontWeight: 'bold',
  }
}));
