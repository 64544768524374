//Type
export const AuthActionTypes = {
  ASYNC_SET_PASSWORD: '@auth/ASYNC_SET_PASSWORD',
  ASYNC_LOGIN_REQUEST: '@auth/ASYNC_LOGIN_REQUEST',
  LOGIN_SUCCESS: '@auth/LOGIN_SUCCESS',
  LOGIN_FAILED: '@auth/LOGIN_FAILED',
  SET_PASSWORD_SUCCESS: '@auth/SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILED: '@auth/SET_PASSWORD_FAILED',
  LOGOUT: '@auth/LOGOUT',
  SET_ERROR: '@auth/SET_ERROR',
  CODE_EXPIRED: '@auth/CODE_EXPIRED',
  ASYNC_CHANGE_PASSWORD: '@auth/ASYNC_CHANGE_PASSWORD',
}

const INITIAL_STATE = {
  loading: false,
  token: '',
  loggedIn: false,
  setPassword: false,
  error: undefined,
  codeExpired: false,
};

export const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AuthActionTypes.ASYNC_LOGIN_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.ASYNC_SET_PASSWORD:
      return { ...state, loading: true };
    case AuthActionTypes.LOGIN_SUCCESS:
      return { ...state, token: payload, loggedIn: true, loading: false };
    case AuthActionTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case AuthActionTypes.SET_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case AuthActionTypes.SET_PASSWORD_FAILED:
      return { ...state, loading: false };
    case AuthActionTypes.SET_ERROR:
      return { ...state, error: payload };
    case AuthActionTypes.CODE_EXPIRED:
      return { ...state, codeExpired: payload }
    default:
      return state;
  }
};

export const setPasswordRequest = (params) => ({
  type: AuthActionTypes.ASYNC_SET_PASSWORD,
  payload: params
});

export const loginRequest = (username, password) => ({
  type: AuthActionTypes.ASYNC_LOGIN_REQUEST,
  payload: { username, password }
});

export const setPasswordSuccess = payload => ({
  type: AuthActionTypes.SET_PASSWORD_SUCCESS,
  payload
});

export const setPasswordFailed = () => ({
  type: AuthActionTypes.SET_PASSWORD_FAILED,
});

export const loginSuccess = payload => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload
});

export const loginFailed = () => ({
  type: AuthActionTypes.LOGIN_FAILED,
});

export const logout = () => ({
  type: AuthActionTypes.LOGOUT,
});

export const setError = (value) => ({
  type: AuthActionTypes.SET_ERROR,
  payload: value
});

export const setCodeExpired = payload => ({
  type: AuthActionTypes.CODE_EXPIRED,
  payload
});

export const asyncChangePassword = params => ({
  type: AuthActionTypes.ASYNC_CHANGE_PASSWORD,
  payload: params
});

export default authReducer;
