import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  asyncExchangeBoleto,
  asyncPlan,
} from "store/reducers/paymentPlan.reducer";
import { routes } from "utils/routers.constants";
import { useStyles } from "views/PaymentPlan/styles/styles";
import { useParams } from "react-router-dom";
import {
  formatCEP,
  formatCNPJ,
  formatCPF,
} from "@brazilian-utils/brazilian-utils";
import { format, parseISO } from "date-fns";
import {
  convertToCurrency,
  documentType,
  formatBackgroundStatusBoleto,
  formatPhoneNumber,
  formatStatusBoleto,
} from "utils/functions";
import { useMemo } from "react";
import { Table } from "components";
import Cart from "components/Cart/Cart";

const ReadPlan = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { plan, loading, loadingBoletoExchange, boletos } = useSelector((state) => state.PaymentPlan);
  const formatedDate = new Date(plan?.customer?.birthDay);
  const [open, setOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState();
  const [selectRow, setSelectRow] = useState({});
  const [expandedUrl, setExpandedUrl] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const cpfTypeDocument = plan?.typeDocument === documentType.CPF;

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleGetPlan = useCallback(() => {
    dispatch(asyncPlan(id));
  }, [dispatch, id]);

  useEffect(() => {
    handleGetPlan();
  }, [handleGetPlan]);

  const columns = [
    { id: "id", label: "id", minWidth: 170 },
    { id: "status", label: "Status", minWidth: 130 },
    { id: "installments", label: "Parcela", minWidth: 170 },
    { id: "expirationDate", label: "Data de Vencimento", minWidth: 170 },
    { id: "price", label: "Valor", minWidth: 170 },
  ];

  const handleExpandedRow = (boleto) => {
    setExpandedId(boleto.id);
    setExpandedUrl(!expandedUrl);
  }

  const BoletoFormated = useMemo(() => {
    if (boletos) {
      return boletos.map((boleto) => ({
        id: boleto.id,
        status: <div style={{
          padding: '10px',
          textAlign: 'center',
          backgroundColor: formatBackgroundStatusBoleto(boleto.status),
          borderRadius: '100px'}}>{formatStatusBoleto(boleto.status)}
        </div>,
        installments: boleto?.installments,
        price: convertToCurrency(boleto.price),
        expirationDate: format(
          parseISO(boleto?.boleto?.expirationDate),
          "dd/MM/yyyy 'às' HH:MM'h'"
        ),
      }));
    }
    return [];
  }, [boletos, expandedUrl, handleExpandedRow]); //eslint-disable-line

  function handleNewExpirationDate() {
    const date = new Date(expirationDate);
    const { id } = selectRow;

    const data = {
      dateToExpired: date,
    };

    dispatch(asyncExchangeBoleto({ id, data }));
  }

  return (
    <Container className={classes.main} maxWidth="xl" component="main">
      <div>
        <Modal
          open={open}
          onClose={() => handleOpen()}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.newBoleto}>
            <h2>Gerar um novo boleto</h2>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <label style={{marginBottom: '10px'}}>Nova Data de Expiração</label>
              <input
                type="date"
                onChange={(e) => setExpirationDate(e.target.value)}
                value={expirationDate}
              />
              {!expirationDate && <span style={{color: 'red', fontSize: '12px', marginTop: '20px'}}>*Selecione uma data de Expiração</span>}
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{marginRight: '10px'}}
                disabled={loadingBoletoExchange || !expirationDate}
                onClick={() => handleNewExpirationDate()}
              >
                {loadingBoletoExchange ? 'Carregando...' : 'Gerar'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen()}
              >
                Voltar
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      {loading ? (
        <div className={classes.loadingCircle}>
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          component="header"
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Typography
            variant="h4"
            component="span"
            className={classes.title_header}
          >
            {plan?.paymentPlan?.name}
          </Typography>

          <Grid className={classes.container} container spacing={2}>
            <h2 className={classes.subTitle}>Dados do usuário</h2>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label={documentType[plan?.typeDocument]}
                defaultValue={
                  cpfTypeDocument
                    ? formatCPF(plan?.document)
                    : formatCNPJ(plan?.document)
                }
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label={cpfTypeDocument ? "Nome" : "Razão Social"}
                defaultValue={cpfTypeDocument ? plan?.customer?.firstName : plan?.customer?.corporateName}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label={cpfTypeDocument ? "Sobrenome" : "Nome Fantasia"}
                defaultValue={cpfTypeDocument ? plan?.customer?.lastName : plan?.customer?.doingBusiness}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className={classes.row}>
              {cpfTypeDocument &&
                <>
                  <TextField
                    item
                    xs={8}
                    label="Sexo"
                    defaultValue={
                      plan?.customer?.gender === "M" ? "Masculino" : "Feminino"
                    }
                    className={classes.inputText}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    item
                    xs={8}
                    label="Data de Nascimento"
                    defaultValue={
                      plan?.customer?.birthDay && format(formatedDate, "dd/MM/yyyy")
                    }
                    className={classes.inputText}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              }
              <TextField
                item
                xs={8}
                label="telefone"
                defaultValue={formatPhoneNumber(plan?.customer?.phone)}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Email"
                defaultValue={plan?.customer?.email}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <h2 className={classes.subTitle}>Dados do Endereço</h2>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label="CEP"
                defaultValue={formatCEP(plan?.customer?.address?.postalCode)}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Cidade"
                defaultValue={plan?.customer?.address?.city}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="País"
                defaultValue={plan?.customer?.address?.country}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label="Estado"
                defaultValue={plan?.customer?.address?.state}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Logradouro"
                defaultValue={plan?.customer?.address?.street}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Número"
                defaultValue={plan?.customer?.address?.number}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Bairro"
                defaultValue={plan?.customer?.address?.district}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <h2 className={classes.subTitle}>Plano de pagamento</h2>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label="Nome do Plano"
                defaultValue={plan?.paymentPlan?.name}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Preço"
                defaultValue={plan?.price && convertToCurrency(plan?.price)}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Tipo de Plano"
                defaultValue={plan?.paymentPlan?.typePlan?.type}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label="Status"
                defaultValue={plan?.status}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Criado em"
                defaultValue={
                  plan?.created_at &&
                  format(new Date(plan.created_at), "dd/MM/yyyy")
                }
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Atualizado em"
                defaultValue={
                  plan?.created_at &&
                  format(new Date(plan.updated_at), "dd/MM/yyyy")
                }
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                item
                xs={8}
                label="Instruções"
                defaultValue={plan?.paymentPlan?.instructions}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className={classes.row}>
              <TextField
                item
                xs={8}
                label="Descrição do Plano"
                defaultValue={plan?.paymentPlan?.description}
                multiline
                maxRows={4}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
              />

            </div>

            <h2 className={classes.subTitle}>Carrinho</h2>

            <div className={classes.cart}>
              <Cart cartsSellers={plan?.cart_sellers} />
            </div>

            <h2 className={classes.subTitle}>Carrinho</h2>

            <div className={classes.cart}>
              <Cart cartsSellers={plan?.cart_sellers} />
            </div>

            <h2 className={classes.subTitle}>Boletos</h2>

            <div style={{ marginBottom: "40px", width: "100%" }}>
              <Table
                action
                error={false}
                data={boletos}
                columns={columns}
                rows={BoletoFormated}
                selectRow={true}
                setSelectRow={setSelectRow}
                actionButton="+ NOVO BOLETO"
                onClickDetails={() => handleOpen()}
                activePages={false}
                expandedId={expandedId}
                expandedUrl={expandedUrl}
                handleExpandedRow={handleExpandedRow}
                haveBoletos={true}
              />
            </div>

          <div className={classes.button}>
            <Button
            color='primary'

            onClick={() => history.push(routes.PAYMENTPLAN)}
            variant="contained"
            >Voltar</Button>
          </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ReadPlan

