export const FinancesActionTypes = {
    ASYNC_ENTRIES_DATA: '@finances/ASYNC_ENTRIES_DATA',
    CHANGE_ENTRIES_DATA: '@finances/CHANGE_ENTRIES_DATA',
    ASYNC_BALANCE: '@finances/ASYNC_BALANCE',
    CHANGE_BALANCE: '@finances/CHANGE_BALANCE',
    CHANGE_LOADING: '@finances/CHANGE_LOADING',
    CHANGE_BALANCE_LOADING: '@finances/CHANGE_BALANCE_LOADING',
    CHANGE_WITHDRAW_LOADING: '@finances/CHANGE_WITHDRAW_LOADING',
    ASYNC_ALL_WITHDRAW: '@finances/ASYNC_ALL_WITHDRAW',
  };

  const INITIAL_STATE = {
    data: {},
    balance: null,
    balanceLoading: true,
    loading: false,
    withdrawLoading: false,
  };

  export const changeLoading = (payload) => ({type: FinancesActionTypes.CHANGE_LOADING, payload});
  export const changeBalanceLoading = (payload) => ({type: FinancesActionTypes.CHANGE_BALANCE_LOADING, payload});
  export const changeWithdrawLoading = (payload) => ({type: FinancesActionTypes.CHANGE_WITHDRAW_LOADING, payload});

  export const asyncEntriesData = (payload) => ({
      type: FinancesActionTypes.ASYNC_ENTRIES_DATA,
      payload,
  });

  export const changeEntriesData = (data) => ({
      type: FinancesActionTypes.CHANGE_ENTRIES_DATA,
      payload: data,
  });

  export const asyncBalance = (payload) => ({
      type: FinancesActionTypes.ASYNC_BALANCE,
      payload,
  });

  export const changeBalance = (payload) => ({
      type: FinancesActionTypes.CHANGE_BALANCE,
      payload,
  });

  export const asyncAllWithdraw = () => ({
      type: FinancesActionTypes.ASYNC_ALL_WITHDRAW,
  });

  export class FinancesSelectors {
    static loading({Finances}) {
      return Finances?.loading;
    }

    static entriesData({Finances}) {
      return Finances?.data;
    }

    static balance({Finances}) {
      return Finances?.balance;
    }

    static balanceLoading({Finances}) {
      return Finances?.balanceLoading;
    }

    static withdrawLoading({Finances}) {
      return Finances?.withdrawLoading;
    }
  }

  function FinancesReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
    case FinancesActionTypes.CHANGE_LOADING:
      return {...state, loading: payload};
    case FinancesActionTypes.CHANGE_BALANCE_LOADING:
      return {...state, balanceLoading: payload};
    case FinancesActionTypes.CHANGE_WITHDRAW_LOADING:
      return {...state, withdrawLoading: payload};
    case FinancesActionTypes.CHANGE_ENTRIES_DATA:
      return {...state, data: payload};
    case FinancesActionTypes.CHANGE_BALANCE:
      return {...state, balance: payload};

    default:
      return state;
    }
  }

  export default FinancesReducer;
