//ResourceTypes
export const TransactionActionTypes = {
  ASYNC_TRANSACTION_DETAILS: '@transactions/ASYNC_TRANSACTION_DETAILS',
  CHANGE_TRANSACTION_DETAILS: '@transactions/CHANGE_TRANSACTION_DETAILS',
  LOADING_TRANSACTION_DETAILS: '@transactions/LOADING_TRANSACTION_DETAILS'
};

const INITIAL_STATE = {
  transaction_details: {},
  loading_details: false
}

const TransactionReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TransactionActionTypes.CHANGE_TRANSACTION_DETAILS:
      return { ...state, transaction_details: payload };
    case TransactionActionTypes.LOADING_TRANSACTION_DETAILS:
      return { ...state, loading_details: payload };
    default:
      return state;
  }
}

//Actions
export const asyncTransactionDetails = (paymentId) => ({
  type: TransactionActionTypes.ASYNC_TRANSACTION_DETAILS,
  payload: paymentId
});

export const changeTransactionDetails = (transaction) => ({
  type: TransactionActionTypes.CHANGE_TRANSACTION_DETAILS,
  payload: transaction
});

export const loadingTransactionDetails = (loading) => ({
  type: TransactionActionTypes.LOADING_TRANSACTION_DETAILS,
  payload: loading
})

export default TransactionReducer;
