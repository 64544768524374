export const SubsellerActionTypes = {
    ASYNC_GET_AVAILABLE_USERS: '@subseller/ASYNC_GET_AVAILABLE_USERS',
    SET_AVAILABLE_USERS: '@subseller/SET_AVAILABLE_USERS',
    ASYNC_LIST_SUBSELLERS: '@subseller/ASYNC_LIST_SUBSELLERS',
    SET_SUBSELLERS: '@subseller/SET_SUBSELLERS',
    ASYNC_GET_SUBSELLER: '@subseller/ASYNC_GET_SUBSELLER',
    SET_SUBSELLER: '@subseller/SET_SUBSELLER',
    ASYNC_SAVE_SUBSELLER: '@subseller/ASYNC_SAVE_SUBSELLER',
    SET_SAVED_SUBSELLER: '@subseller/SET_SAVED_SUBSELLER',
}

const INITIAL_STATE = {
    availableUsers: [],
    subsellers: [],
    total: -1,
    loading: true,
    loadingSubseller: false,
    subseller: null,
    saving: null,
}

export const subsellerReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SubsellerActionTypes.SET_AVAILABLE_USERS:
            return {
                ...state,
                availableUsers: payload.users,
            };

        case SubsellerActionTypes.ASYNC_LIST_SUBSELLERS:
            return {                
                ...state,
                loading: true,
            };

        case SubsellerActionTypes.SET_SUBSELLERS:
            return {
                ...state,
                subsellers: payload.subsellers,
                total: payload.total,
                loading: false,
            };

        case SubsellerActionTypes.ASYNC_GET_SUBSELLER:
            return {
                ...state,
                loadingSubseller: true,
            };

        case SubsellerActionTypes.SET_SUBSELLER:
            return {
                ...state,
                loadingSubseller: false,
                subseller: payload,
            };

        case SubsellerActionTypes.ASYNC_SAVE_SUBSELLER:
            return {
                ...state,
                saving: true,
            };

        case SubsellerActionTypes.SET_SAVED_SUBSELLER:
            return {
                ...state,
                saving: false,
                subseller: null,
            };

        default:
            return state;
    }
}

export const getAvailableUsers = () => ({
    type: SubsellerActionTypes.ASYNC_GET_AVAILABLE_USERS,
});

export const setAvailableUsers = (payload) => ({
    type: SubsellerActionTypes.SET_AVAILABLE_USERS,
    payload,
});

export const listSubsellers = (payload) => ({
    type: SubsellerActionTypes.ASYNC_LIST_SUBSELLERS,
    payload,
});

export const setSubsellers = (payload) => ({
    type: SubsellerActionTypes.SET_SUBSELLERS,
    payload,
});

export const getSubseller = (payload) => ({
    type: SubsellerActionTypes.ASYNC_GET_SUBSELLER,
    payload,
});

export const setSubseller = (payload) => ({
    type: SubsellerActionTypes.SET_SUBSELLER,
    payload,
});

export const saveSubseller = (payload) => ({
    type: SubsellerActionTypes.ASYNC_SAVE_SUBSELLER,
    payload,
});

export const setSavedSubseller = (payload) => ({
    type: SubsellerActionTypes.SET_SAVED_SUBSELLER,
    payload,
});

export default subsellerReducer;
