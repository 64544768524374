export const urlAuth = process.env.REACT_APP_API_MAIN_BASE_URL;
export const urlBalance = process.env.REACT_APP_API_SCHEDULES_BASE_URL;
export const urlTransactions = process.env.REACT_APP_API_TRANSACTIONS_BASE_URL;
export const urlReports = process.env.REACT_APP_API_REPORTS_BASE_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID_BACKOFFICE;
export const urlFinances = process.env.REACT_APP_API_ACCOUNTS_BASE_URL;
export const urlPaymentPlan = process.env.REACT_APP_API_PAYMENT_PLANS_BASE_URL;

export const urls = {
  LOGIN: `${urlAuth}/auth`,
  RECOVER_PASSWORD: `${urlAuth}/auth/recover-password`,
  CHANGE_PASSWORD: `${urlAuth}/auth/change-password`,
  SCHEDULE_BALANCE: `${urlBalance}/backoffice/balance-extended`,
  SCHEDULE_EVENTS: `${urlBalance}/backoffice/balance-extended`,
  SCHEDULE_TRANSACTIONS: `${urlTransactions}/backoffice/transactions`,
  TRANSACTION: `${urlTransactions}/backoffice/transactions`,
  REPORTS: `${urlReports}/reports`,
  ENTRIES: `${urlFinances}/portal/checking-accounts/statement`,
  BALANCE: `${urlFinances}/portal/checking-accounts/balance`,
  WITHDRAW: `${urlFinances}/portal/checking-accounts/withdraw`,
  USERS: `${urlAuth}/users`,
  SUBSELLERS: `${urlAuth}/subsellers`,
  AVAILABLE_USERS: `${urlAuth}/subsellers/available_users`,
  SCHEDULES: `${urlBalance}/backoffice/schedules/advanced`,
  SCHEDULES_STATS: `${urlBalance}/backoffice/schedules/advanced/stats`,
  PAYMENT_PLAN: `${urlPaymentPlan}/v1/payment-plan`,
}
