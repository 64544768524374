import { PaymentPlanActionTypes, setBoletos, setLoading, setLoadingBoletoExchange, setLoadingDisablePlan, setPlan, setPlans } from "store/reducers/paymentPlan.reducer";
import handleError from "utils/handleError";
import { postExchangeBoleto, getPaymentPlanBoletos, getPaymentsPlan, getPaymentsPlanRead, deleteDisableBoleto } from "utils/web-api";
import Notification from 'utils/notifications';

const { takeEvery, all, call, put, delay, select } = require("redux-saga/effects");

function* asyncPaymentPlans({payload}) {
    const {page, pageSize} = payload;
    try {
      yield put(setLoading(true));
      const token = yield select(state => state.Auth.token);
      const response = yield call(() => getPaymentsPlan(token, page, pageSize));
      yield put(setPlans(response));
    } catch (error) {
      handleError(error);
    } finally {
      yield put(setLoading(false));
    }
  }

  function* asyncPaymentPlan({id}) {
    try {
      yield put(setLoading(true));
      const token = yield select(state => state.Auth.token);
      const response = yield call(() => getPaymentsPlanRead(id, token));
      const responseBoletos = yield call(() => getPaymentPlanBoletos(id, token));
      yield put(setBoletos(responseBoletos));
      yield put(setPlan(response));
    } catch (error) {
      handleError(error);
      console.log(error);
    } finally {
      yield put(setLoading(false));
    }
  }

  function* asyncExchangeBoleto(payload) {
    const {id, data} = payload.payload;
    try {
      yield put(setLoadingBoletoExchange(true));
      const token = yield select(state => state.Auth.token);
      yield call(() => postExchangeBoleto(id, data, token))
      Notification('success', 'Boleto gerado com sucesso!');
      yield delay(2000);
      window.location.reload(true);
    } catch (error) {
      handleError(error);
      Notification('error', `${error.response.data.detail}`);
    } finally {
      yield put(setLoadingBoletoExchange(false));
    }
  }

  function* asyncDisablePlan(payload) {
    const {id} = payload.payload;
    try {
      yield put(setLoadingDisablePlan(true));
      const token = yield select(state => state.Auth.token);
      yield call(() => deleteDisableBoleto(id, token))
      Notification('success', 'Plano desabilitado com sucesso!');
      yield delay(2000);
      window.location.reload(true);
    } catch (error) {
      handleError(error);
      Notification('error', `${error.response.data.detail}`);
    } finally {
      yield put(setLoadingDisablePlan(true));
    }
  }

  export default function* MySaga() {
    yield all([
      yield takeEvery(PaymentPlanActionTypes.ASYNC_PAYMENT_PLANS, asyncPaymentPlans),
      yield takeEvery(PaymentPlanActionTypes.ASYNC_PLAN, asyncPaymentPlan),
      yield takeEvery(PaymentPlanActionTypes.ASYNC_EXCHANGE_BOLETO, asyncExchangeBoleto),
      yield takeEvery(PaymentPlanActionTypes.ASYNC_DISABLE_PLAN, asyncDisablePlan),
    ]);
  }
