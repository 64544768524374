import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Backdrop, Fade, IconButton, Icon, TextField, Grid, CircularProgress } from '@material-ui/core';

import { Table } from 'components';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { Alert } from '@material-ui/lab';

const columns = [
  { id: 'name', label: 'NOME FANTASIA', minWidth: 170 },
  { id: 'corporate_name', label: 'RAZÃO SOCIAL', minWidth: 170, },
  {
    id: 'document',
    label: 'DOCUMENTO',
    minWidth: 170,
  },
];

const QTD_MIN_COLUMNS = 3;

function ModalSubSellers({ onClose, onChange, open }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [subsellersList, setSubsellersList] = useState([]);

  const user = useSelector(({ User }) => User);
  const isAdmin = user?.admin;

  if (isAdmin && columns.length === QTD_MIN_COLUMNS) {
    columns.unshift({ id: 'id', label: 'ID', minWidth: 170 });
  }

  const subsellers = useMemo(() => {
    return user?.subsellers && user?.subsellers?.filter(({id}) => id !== user?.subsellerId);
  }, [user]);

  const activedSubseller = useMemo(() => {
    return user?.subsellers && user?.subsellers?.find(({id}) => id === user?.subsellerId);
  }, [user]);

  useEffect(() => {
    if (user && user?.subsellers) {
      setSubsellersList(subsellers);
    }
  }, [user, subsellers]);

  const handleSearch = (value) => {
    setSearch(value);
    setSubsellersList(subsellers?.filter(({id, name, corporate_name, document}) =>
      name.toLowerCase().includes(value.toLowerCase()) ||
      corporate_name.toLowerCase().includes(value.toLowerCase()) ||
      document.replace(/[./-]/g, '').includes(value) ||
      (isAdmin ? id.toLowerCase().includes(value.toLowerCase()) : false)
    ));
  };

  const handleSelect = (value) => {
    setLoading(true)
    onChange(value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <main className={classes.paper}>
          <header className={classes.header}>
            <span>Selecionar Subseller</span>
            <IconButton className={classes.button_close} onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </header>

          <section className={classes.section}>
            <Grid
              container
              component='div'
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <TextField
                value={search}
                variant="outlined"
                margin="normal"
                label="Buscar"
                onChange={e => handleSearch(e.target.value)}
                disabled={search.length === 0 && subsellersList?.length === 0}
                fullWidth
                autoFocus
              />
              {!loading && (
                <div className={classes.flex_columns}>
                  {isAdmin && (
                    <div className={classes.flex_columns_row}>
                      <strong>ID</strong>
                      <span>{activedSubseller?.id}</span>
                    </div>
                  )}
                  <div className={classes.flex_columns_row}>
                    <strong>Nome Fantasia</strong>
                    <span>{activedSubseller?.name}</span>
                  </div>
                  <div className={classes.flex_columns_row}>
                    <strong>Razão Social</strong>
                    <span>{activedSubseller?.corporate_name}</span>
                  </div>
                  <div className={classes.flex_columns_row}>
                    <strong>Documento</strong>
                    <span>{activedSubseller?.document}</span>
                  </div>
                </div>
              )}
              {subsellersList?.length === 0
                ? <Alert severity="info" style={{width: '100%'}}>Nenhum registro encontrado!</Alert>
                : loading
                  ? <div className={classes.centered}><CircularProgress size={60} /></div>
                  : (
                    <Table
                      action={true}
                      activePages={false}
                      columns={columns}
                      rows={subsellersList}
                      actionButton="Selecionar"
                      onClickDetails={(value) => handleSelect(value)}
                    />
                  )
              }
            </Grid>
          </section>
        </main>
      </Fade>
    </Modal>
  );
}

export default ModalSubSellers;
