import { all } from 'redux-saga/effects';

import Auth from './auth.saga';
import User from './user.saga';
import Schedule from './schedule.saga';
import Transaction from './transaction.saga';
import Reports from './reports.saga';
import Finances from './finances.saga';
import Subseller from './subseller.saga.js';
import PaymentPlan from './paymentPlan.saga'

export default function* RootSaga() {
  yield all([
    Auth(),
    User(),
    Schedule(),
    Transaction(),
    Reports(),
    Finances(),
    Subseller(),
    PaymentPlan(),
  ]);
};
