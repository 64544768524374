import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { convertToCurrency } from 'utils/functions';

export default function BasicTable({cartsSellers}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight: 'bold'}}>ID</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="left">Valor</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="left">ID do Produto</TableCell>
            <TableCell style={{fontWeight: 'bold'}} align="left">ID do Vendedor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartsSellers?.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.opportunityId}
              </TableCell>
              <TableCell align="left">{convertToCurrency(row.price)}</TableCell>
              <TableCell align="left">{row.productId}</TableCell>
              <TableCell align="left">{row.sellerId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
    cartsSellers: PropTypes.any.isRequired
};