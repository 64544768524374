import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText, IconButton, Icon, Drawer, Typography } from '@material-ui/core';

import { useStylesHeader } from "./styles/styles";

function MenuMobile({ Items, onLinkRouter, onLogout }) {
  const classes = useStylesHeader();
  const { pathname } = useLocation();

  const user = useSelector(({ User }) => User);

  const [anchorEl, setAnchorEl] = useState(null);
  const { admin } = useSelector(({ User }) => User);
  const userManagementId = 5;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleRouter = useCallback((router) => {
    handleClose(null)
    onLinkRouter(router);
  }, [onLinkRouter, handleClose]);

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        edge='start'
        color="inherit"
        onClick={handleClick}
        size='medium'
      >
        <Icon fontSize='large'>menu</Icon>
      </IconButton>
      {Items &&
        <Drawer
          id="customized-menu"
          anchor={'left'}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}

        >
          <div className={classes.header_logout_mobile}>
            <Typography variant='subtitle1' component='span' className={classes.nickname}>
              {user.name}
            </Typography>
            <IconButton size='small' onClick={onLogout}>
              <Icon>exit_to_app</Icon>
            </IconButton>
          </div>
          {Items.map(item => (
            <div key={item.id}>
              {(item.id === userManagementId && !admin ) ? '' :
                <MenuItem
                  key={item.id}
                  onClick={() => handleRouter(item.router)}
                  style={{
                    backgroundColor: pathname === item.router && '#21619e',
                    color: pathname === item.router && '#fff'
                  }}
                >
                  <ListItemIcon>
                    <Icon style={{ color: pathname === item.router && '#fff' }}>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </MenuItem>
              } 
            </div>
          ))}
        </Drawer>
      }
    </>
  );
}

export default MenuMobile;
