import { call, all, takeEvery, put } from 'redux-saga/effects';

import { ScheduleActionTypes, changeScheduleBalance, changeScheduleEvents, changeScheduleTransactions } from 'store/reducers/schedule.reducer';
import { getScheduleBalance, getScheduleEvents, getScheduleTransactions } from 'utils/web-api';
import { loadingStart, loadingStop } from 'store/reducers/loading.reducer';
import Notification from 'utils/notifications';

function* scheduleBalance() {
  try {
    yield put(loadingStart());

    const response = yield call(getScheduleBalance);
    yield put(changeScheduleBalance(response));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingStop());
  }
}

function* scheduleEvents({ payload }) {
  try {
    yield put(loadingStart());

    const response = yield call(getScheduleEvents, payload);
    yield put(changeScheduleEvents(response));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingStop());
  }
}

function* scheduleTransactions({ payload }) {
  try {
    yield put(loadingStart());

    const response = yield call(getScheduleTransactions, payload);
    yield put(changeScheduleTransactions(response));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingStop());
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(ScheduleActionTypes.ASYNC_SCHEDULE_BALANCE, scheduleBalance),
    yield takeEvery(ScheduleActionTypes.ASYNC_SCHEDULE_EVENTS, scheduleEvents),
    yield takeEvery(ScheduleActionTypes.ASYNC_SCHEDULE_TRANSACTIONS, scheduleTransactions),
  ]);
}
