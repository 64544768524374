import {formatCPF, formatCNPJ, formatCurrency} from '@brazilian-utils/brazilian-utils';

export const formatMoney = (int) => {
  let tmp = int + '',
    neg = false;

  if (tmp.indexOf("-") === 0) {
    neg = true;
    tmp = tmp.replace("-", "");
  }

  if (tmp.length === 1) tmp = "0" + tmp

  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if (tmp.length > 6)
    tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  if (tmp.length > 9)
    tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");

  if (tmp.length > 12)
    tmp = tmp.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3,$4");

  if (tmp.indexOf(".") === 0) tmp = tmp.replace(".", "");
  if (tmp.indexOf(",") === 0) tmp = tmp.replace(",", "0,");

  return (neg ? (''.concat('-', 'R$ ', tmp)) : (''.concat('R$ ', tmp)));
}

export const debounceFunc = debounceEvent();
function debounceEvent(time_key_up) {
  return (fn, wait = 1000) => clearTimeout(time_key_up, time_key_up = setTimeout(() => fn(), wait));
}

export function checkCurrentDate(year, month, day) {
  const currentDate = new Date().toDateString();
  const date = new Date(year, month, day).toDateString();

  if (currentDate === date)
    return true;

  return false;
}

export function formatPaymentEvent(payment_type, payment_mode) {
  switch (payment_type) {
    case 'CreditCard':
      return "Cartão de Crédito Online";
    case 'Boleto':
      return 'Boleto';
    case 'Lio':
      switch (payment_mode) {
        case 'CreditCard':
          return "Cartão de Crédito Presencial";
        case 'DebitCard':
          return "Cartão de Débito Presencial";
        default:
          return "";
      }
    default:
      return "";
  }
}

export function formatTransaction(value) {
  switch (value) {
    case 1:
      return 'Autorizado';
    case 2:
      return 'Capturado'
    case 3:
      return 'Negado'
    case 10:
      return 'Cancelado no mesmo dia'
    case 11:
      return 'Cancelado'
    default:
      return 'Desconhecido'
  }
}

export function formatStatusBoleto(value) {
  switch (value) {
    case 'PaymentApproved':
      return 'Boleto Pago';
    case 'BoletoGenerated':
      return 'Boleto Gerado'
    case 'BoletoExchanged':
      return 'Boleto Substituído'
    default:
      return 'Status Desconhecido'
  }
}

export function formatBackgroundStatusBoleto(value) {
  switch (value) {
    case 'PaymentApproved':
      return '#EDF7ED';
    case 'BoletoGenerated':
      return '#E5F6FC'
    case 'BoletoExchanged':
      return '#FDECED'
    default:
      return 'Status Desconhecido'
  }
}

export function formatReportsStatus(value) {
  switch (value) {
    case 'CREATING':
      return 'PREPARANDO';
    case 'GENERATING':
      return 'GERANDO';
    case 'GENERATED':
      return 'GERADO';
    case 'FAILED':
      return 'ERRO';
    default:
      return "Desconhecido";
  }
}

export const paymentTypes = {
  CreditCard: 'CreditCard',
  DebitCard: 'DebitCard',
  Boleto: 'Boleto',
};

export const paymentTypesDesc = {
  CreditCard: 'Cartão de crédito online',
  Lio: 'Cartão presencial',
  Boleto: 'Boleto',
};

export const transactionStatusType = (status, _void) => {
  switch (status) {
    case 2: return _void ? 'Estorno Parcial' : 'Capturado';
    case 10: return 'Cancelado';
    case 11: return 'Estornado'
    default: return '';
  }
};

export function formatDocument({type, number}) {
  switch (type.toUpperCase()) {
    case 'CPF': return formatCPF(number);
    case 'CNPJ': return formatCNPJ(number);
    default: return '';
  }
}

export function formatUserStatus(value) {
  switch (value) {
    case true: return 'Ativo'
    case false: return 'Inativo'
    default: return '';
  }
}

export function formatUserLevel(value) {
  switch (value) {
    case true: return 'Administrador'
    case false: return 'Usuário'
    default: return '';
  }
}

export function convertToCurrency(value = 0) {
  return `R$ ${formatCurrency(value / 100)}`;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return;
  }
  return phoneNumber.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
}

export function compareDate(data) {
  const dataStr = data;
    const dataLimpa = dataStr.split('às')[0].trim();

    const partesData = dataLimpa.split('/');
    const dia = parseInt(partesData[0], 10);
    const mes = parseInt(partesData[1] - 1, 10);
    const ano = parseInt(partesData[2], 10);

    const dataFormatada = new Date(ano, mes, dia);
    const dataAtual = new Date();

    const isDataMaiorQueAtual = dataFormatada > dataAtual;
    return isDataMaiorQueAtual;
}

export const planStatus = status => {
  if (status === 'Creating') {
    return 'Criando'
  } else if (status === 'Created') {
    return 'Criado'
  } else if (status === 'CreationError') {
    return 'Erro na criação'
  } else if (status === 'Canceled') {
    return 'Cancelado'
  } else {
    return 'Concluído'
  }
}

export const documentType = {
  CPF: 'CPF',
  CNPJ: 'CNPJ'
}