import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';

import { theme } from 'assets/theme/theme';

import Routes from 'routes';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/global-styles.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes />
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
