import { routes } from 'utils/routers.constants';

function breadcrumbsFunc(router) {
  switch (router) {
    case routes.ROOT:
      return [{ to: routes.ROOT, label: 'Dashboard' }]
    case routes.SALES_REPORTS:
      return [
        { to: routes.SALES_REPORTS, label: 'Relatório de vendas' },
      ];
    case routes.SALE_REPORT_DETAILS:
      return [
        { to: routes.SALES_REPORTS, label: 'Relatório de vendas' },
        { to: routes.SALE_REPORT_DETAILS, label: 'Detalhes do pagamento' }
      ];
    case routes.FINANCIAL_AGENDA:
      return [{ to: routes.FINANCIAL_AGENDA, label: 'Agenda Financeira' },];
    case routes.DASHBOARD:
      return [{ to: routes.DASHBOARD, label: 'Dashboard' }];
    default:
      return [];
  }
}

export default breadcrumbsFunc;
