import { call, put, all, takeEvery, debounce } from 'redux-saga/effects';
import handleError, { handleSuccess } from 'utils/handleError';
import { SubsellerActionTypes, setAvailableUsers, setSubsellers, setSubseller, setSavedSubseller } from '../reducers/subseller.reducer.js';
import * as WebApi from 'utils/web-api';

function* getAvailableUsers() {
    try {
        const data = yield call(WebApi.getAvailableUsers);
        yield put(setAvailableUsers(data));
    } catch (error) {
        handleError(error);
    }
}

function* listSubsellers({ payload: params }) {
    try {
        const data = yield call(WebApi.listSubsellers, params);
        yield put(setSubsellers(data));
    } catch (error) {
        handleError(error);
    }
}

function *getSubseller({payload: id}) {
    try {
        const data = yield call(WebApi.getSubseller, id);
        yield put(setSubseller(data));
    } catch (error) {
        handleError(error);
    }
}

function *saveSubseller({ payload: {id, data} }) {
    try {
        const _data = yield call(WebApi.postSubseller, {id, data});
        yield put(setSavedSubseller(_data));
        handleSuccess('Subseller salvo com sucesso');
    } catch (error) {
        handleError(error);
    }
}

export default function* MySaga() {
    yield all([
        yield takeEvery(SubsellerActionTypes.ASYNC_GET_AVAILABLE_USERS, getAvailableUsers),
        yield debounce(500, SubsellerActionTypes.ASYNC_LIST_SUBSELLERS, listSubsellers),
        yield takeEvery(SubsellerActionTypes.ASYNC_GET_SUBSELLER, getSubseller),
        yield takeEvery(SubsellerActionTypes.ASYNC_SAVE_SUBSELLER, saveSubseller),
    ]);
}
