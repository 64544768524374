import { Delete, Get, Post, Put } from "./api-base"
import { urls } from "./urls.constants";
import Axios from "axios";

export const postRecoverPassword = async (params) => {
  return await Axios.post(`${urls.RECOVER_PASSWORD}`, params, { headers: { "Content-Type": " application/json" } });
}

export const getScheduleBalance = () => {
  return Get(`${urls.SCHEDULE_BALANCE}`);
}

export const getScheduleEvents = (date) => {
  return Get(`${urls.SCHEDULE_EVENTS}/${date}`);
}

export const getScheduleTransactions = (params) => {
  return Get(`${urls.SCHEDULE_TRANSACTIONS}`, params);
}

export const getTransactionDetails = (paymentId) => {
  return Get(`${urls.TRANSACTION}/${paymentId}`);
}

export const getReportsList = (params) => {
  return Get(`${urls.REPORTS}`, params);
}

export const postReports = (params) => {
  return Post(`${urls.REPORTS}`, params);
}

export const getReportDownload = (reportId) => {
  return Get(`${urls.REPORTS}/${reportId}/download`);
}

export const postChangePassword = (params) => {
  return Post(`${urls.CHANGE_PASSWORD}`, params);
}

export const getEntries = () => {
  return Get(urls.ENTRIES);
}

export const getBalance = () => {
  return Get(urls.BALANCE);
}

export const requestAllWithdraw = () => {
  return Post(`${urls.WITHDRAW}/all`);
}

export const getUsers = (params) => {
  return Get(`${urls.USERS}`, params);
}

export const postUsers = (params) => {
  return Post(`${urls.USERS}`, params)
}

export const getUserById = (userId) => {
  return Get(`${urls.USERS}/${userId}`)
}

export const putUser = (userId, data) => {
  return Put(`${urls.USERS}/${userId}`, data)
}

export const getAvailableUsers = () => {
  return Get(urls.AVAILABLE_USERS);
}

export const listSubsellers = (params) => {
  return Get(urls.SUBSELLERS, params);
}

export const getSubseller = (id) => {
  return Get(`${urls.SUBSELLERS}/${id}`);
}

export const postSubseller = ({id, data}) => {
  return Post(`${urls.SUBSELLERS}/${id}`, data);
}

export const getSchedules = (params) => {
  return Get(urls.SCHEDULES, params);
};

export const getSchedulesStats = () => {
  return Get(urls.SCHEDULES_STATS);
};

export const getPaymentsPlan = (token, page, pageSize) => {
  return Get(`${urls.PAYMENT_PLAN}/plans?page=${page}&pageSize=${pageSize}`, null, {
    'Authorization': `Bearer ${token}`
  });
};

export const getPaymentsPlanRead = (id, token) => {
  return Get(`${urls.PAYMENT_PLAN}/plans/${id}`, null, {
    'Authorization': `Bearer ${token}`
  });
};

export const getPaymentPlanBoletos = (id, token) => {
  return Get(`${urls.PAYMENT_PLAN}/plans/${id}/boletos`, null, {
    'Authorization': `Bearer ${token}`
  });
};

export const postExchangeBoleto = (id, data, token) => {
  return Post(`${urls.PAYMENT_PLAN}/boletos/${id}/exchange`, data, {
    'Authorization': `Bearer ${token}`
  });
};

export const deleteDisableBoleto = (id, token) => {
  return Delete(`${urls.PAYMENT_PLAN}/plans/${id.id}`, {
    'Authorization': `Bearer ${token}`
  });
};
