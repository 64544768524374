import { call, all, takeEvery, put } from 'redux-saga/effects';

import { ReportsActionTypes, changeReportsList, loadingReports, successReports } from 'store/reducers/reports.reducer';
import { loadingStart, loadingStop } from 'store/reducers/loading.reducer';
import { getReportsList, postReports, getReportDownload } from 'utils/web-api';
import Notification from 'utils/notifications';

function* reportsList({ payload }) {
  try {
    yield put(loadingStart(true));

    const response = yield call(getReportsList, payload);
    yield put(changeReportsList(response));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingStop(false));
  }
}

function* reportsGenerate({ payload }) {
  try {
    yield put(loadingReports(true));

    const response = yield call(postReports, payload);
    yield put(changeReportsList(response));
    yield put(successReports(true));
    Notification('success', 'O relatório está sendo gerado. Você pode consultar o status na tela de Todos os Relatórios.');
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingReports(false));
  }
}

function* reportDownload({ payload }) {
  try {
    yield put(loadingReports(true));

    const response = yield call(getReportDownload, payload);
    window.location.assign(response.url);
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingReports(false));
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(ReportsActionTypes.ASYNC_REPORTS_LIST, reportsList),
    yield takeEvery(ReportsActionTypes.ASYNC_GENERATE_REPORT, reportsGenerate),
    yield takeEvery(ReportsActionTypes.ASYNC_REPORT_DOWNLOAD, reportDownload),
  ]);
}
