import { call, put, all, takeEvery, takeLatest, debounce } from 'redux-saga/effects';
import handleError, { handleSuccess } from 'utils/handleError';
import { formatDocument } from 'utils/functions';
import { UserActionTypes, setUserInfo, setSubSellers, changeSubSellerId, setUsersList, postNewUsers, setUserById, setUser, loadingInfoByUser, loadingUsersList } from '../reducers/user.reducer';
import { getUserById, getUsers, postUsers, putUser } from 'utils/web-api';
import { loginApi } from '../../services/api';
import Notification from 'utils/notifications';

function* userInfo() {
  try {
    const response = yield call(loginApi.get, '/me');
    yield put(setUserInfo(response.data));
  } catch (error) {
    handleError(error);
  }
}

function* getSubSellers() {
  try {
    const response = yield call(loginApi.get, '/me/subsellers');
    const subsellersFormatted = response?.data.map(subseller => ({
      ...subseller,
      name: subseller.name?.toUpperCase(),
      document: formatDocument(subseller.document),
    }))
    yield put(setSubSellers(subsellersFormatted));
  } catch (error) {
    handleError(error);
  }
}

function* changeSubSeller({ payload }) {
  try {
    yield put(changeSubSellerId(''));
    const headers = { headers: {"Content-Type": "application/json"} };
    yield call(loginApi.put, '/me/subsellerId', JSON.stringify(payload), headers);
    window.location.reload();
  } catch (error) {
    handleError(error);
  }
}

function* usersList({payload}) {
  try {      
    yield put(loadingUsersList());
    const response = yield call(getUsers, {...payload, page: payload.page -1});
    yield put(setUsersList(response));
  } catch (error) {
    handleError(error);
  }
}

function* newUsers({ payload }) {
  try {
    const response = yield call(postUsers, payload)
    yield put(postNewUsers(response));
    handleSuccess('Usuário cadastrado.');
  } catch (error) {
    if({type: 'conflict'}){
      Notification('error', 'Este email já está cadastrado.');
    }else{
      Notification('error', 'Ocorreu um erro inesperado, por favor, tente novamente.')
    }
  }
}

function* getUsersIdById ({ payload }) {
  try {  
    yield put(loadingInfoByUser(true))
    const selectUser = yield call(getUserById, payload);
    yield put(setUserById(selectUser));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, por favor, tente novamente.')
  }
  finally{
    yield put(loadingInfoByUser(false))
  }
}

function* editUser( { payload } ) {
  try {     
    const response = yield call(putUser, payload.id, payload.data);
    yield put(setUser(response));
    handleSuccess('Usuário alterado com sucesso.');
  } catch (error) {
    if (error.response) {
      const { error_description } = error.response.data;
      if (error_description === 'User must be a subsellers') {
        Notification('error', 'Usuário deve estar associado a pelo menos um subseller.')
        return;
      }
      if (error_description === 'User cannot edit himself') {
        Notification('error', 'Desculpe! Você não pode editar seu próprio usuário.')
        return;
      }
    }

    Notification('error', 'Ocorreu um erro inesperado, por favor, tente novamente.')    
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(UserActionTypes.ASYNC_GET_USER_INFO, userInfo),
    yield takeEvery(UserActionTypes.ASYNC_GET_SUBSELLERS, getSubSellers),
    yield takeEvery(UserActionTypes.ASYNC_CHANGE_SUBSELLER, changeSubSeller),
    yield debounce(500, UserActionTypes.GET_USERS_LIST, usersList),
    yield takeLatest(UserActionTypes.CREATE_NEW_USERS, newUsers),
    yield takeLatest(UserActionTypes.GET_USER_BY_ID, getUsersIdById),
    yield takeLatest(UserActionTypes.UPDATE_USER, editUser),
  ]);
}
