import { call, all, takeEvery, put } from 'redux-saga/effects';

import { TransactionActionTypes, changeTransactionDetails, loadingTransactionDetails } from 'store/reducers/transaction.reducer';
import { getTransactionDetails } from 'utils/web-api';
import Notification from 'utils/notifications';

function* transactionDetails({ payload }) {
  try {
    yield put(loadingTransactionDetails(true));

    const response = yield call(getTransactionDetails, payload);
    yield put(changeTransactionDetails(response));
  } catch (error) {
    Notification('error', 'Ocorreu um erro inesperado, tente novamente.');
  } finally {
    yield put(loadingTransactionDetails(false));
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(TransactionActionTypes.ASYNC_TRANSACTION_DETAILS, transactionDetails)
  ]);
}
