import localStorage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'

export default reducers => {
  const persistedReducer = persistReducer({
    key: 'cispay-backoffice',
    storage: localStorage,
    whitelist: ['Auth', 'User']
  }, reducers)

  return persistedReducer
}
