import { call, put, all, takeLatest } from 'redux-saga/effects';

import {
  AuthActionTypes,
  loginSuccess,
  loginFailed,
  setPasswordSuccess,
  setPasswordFailed,
  setError, setCodeExpired
} from '../reducers/auth.reducer';
import { getUserInfo } from '../reducers/user.reducer';
import { loadingStart, loadingStop } from 'store/reducers/loading.reducer';
import { loginApi, defaultApi } from '../../services/api'
import { clientId } from 'utils/urls.constants';
import handleError, { handleSuccess } from 'utils/handleError';
import { history } from '../store-config';
import { postChangePassword } from 'utils/web-api';

function* loginRequest({ payload }) {
  try {
    const { username, password } = payload;
    const response = yield call(loginApi.post, '/token', {
      grant_type: "password",
      username,
      password,
      scope: "dev",
      client_id: clientId
    });

    const token = response.data.access_token
    defaultApi.defaults.headers.Authorization = `Bearer ${token}`
    loginApi.defaults.headers.Authorization = `Bearer ${token}`

    yield put(loginSuccess(token));
    yield put(getUserInfo());
  } catch (error) {
    const { response } = error;
    if (response?.status === 400) {
      const { data } = response;
      let message = data.error_description && (data.error_description.includes('username') && data.error_description.includes('password'))
        ? 'Usuário ou senha incorretos.'
        : 'Campos incorretos.';
      yield put(setError(message));
    } else {
      handleError(error);
    }
    yield put(loginFailed());
  }
}

function* setPassword({ payload }) {
  try {
    loginApi.defaults.headers.Authorization = `Bearer ${payload.token}`
    yield call(loginApi.post, '/reset-password', {
      password: payload.password
    })
    yield put(setPasswordSuccess());
    handleSuccess('A troca foi feita com sucesso. Agora você já pode fazer login com sua nova senha.');
    payload.history.push('/');
  } catch (err) {
    const { response } = err;

    yield put(setPasswordFailed());

    if (response?.status === 401) {
      yield put(setCodeExpired(true));
      return;
    }

    handleError('Ocorreu um erro inesperado, não foi possível trocar a senha');
  }
}

function* changePassword({ payload }) {
  try {
    yield put(loadingStart());

    yield call(postChangePassword, payload);

    handleSuccess('A troca foi feita com sucesso. Agora você já pode fazer login com sua nova senha.');
    history.push('/');
  } catch (error) {
    handleError('Ocorreu um erro inesperado, não foi possível trocar a senha');
  } finally {
    yield put(loadingStop());
  }
}

function* setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.Auth;

  if (token) {
    defaultApi.defaults.headers.Authorization = `Bearer ${token}`
    loginApi.defaults.headers.Authorization = `Bearer ${token}`
  }
  yield put(setError(undefined));
}

export default function* MySaga() {
  yield all([
    yield takeLatest(AuthActionTypes.ASYNC_LOGIN_REQUEST, loginRequest),
    yield takeLatest(AuthActionTypes.ASYNC_SET_PASSWORD, setPassword),
    yield takeLatest(AuthActionTypes.ASYNC_CHANGE_PASSWORD, changePassword),
    yield takeLatest('persist/REHYDRATE', setToken),

  ]);
}
