import { routes } from "utils/routers.constants";

export const DataHeader = [
  {
    id: 2,
    title: 'Agenda Financeira',
    router: routes.FINANCIAL_AGENDA,
    icon: 'date_range',
    admin: false,
  },
  {
    id: 3,
    title: 'Relatório de Vendas',
    router: routes.SALES_REPORTS,
    icon: 'assignment',
    admin: false,
  },
  {
    id: 4,
    title: 'Todos os Relatórios',
    router: routes.ALL_REPORTS,
    icon: 'request_page',
    admin: false,
  },
  {
    id: 5,
    title: 'Finanças',
    router: routes.FINANCES,
    icon: 'account_balance',
    admin: false,
  },
  {
    id: 6,
    title: 'Gerenciamento de usuários',
    router: routes.USERS_MANAGEMENT,
    icon: 'person_page',
    admin: true,
  },
  {
    id: 7,
    title: 'Gerenciamento de subsellers',
    router: routes.SUBSELLERS,
    icon: 'corporate_fare',
    admin: true,
  },
  {
    id: 8,
    title: 'Recebíveis',
    router: routes.SCHEDULES,
    icon: 'attach_money',
    admin: true,
  },
  {
    id: 9,
    title: 'Planos de Pagamento',
    router: routes.PAYMENTPLAN,
    icon: 'local_atm_outlined_icon',
    admin: false,
  },
];
