import { makeStyles } from '@material-ui/core/styles';

export const useStylesTable = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  head: {
    backgroundColor: '#fff',
    fontWeight: "bold"
  },
  container: {
    maxHeight: 'calc(100vh - 246px)',
    backgroundColor: "#fff"
  },
  footer: {
    backgroundColor: "#fff",
  },
  buttonAction: {
    color: '#fff'
  },
  urlBoletoSuccess: {
    color: '#2E7C33',
    'textDecoration': 'none',

    '&:hover': {
      'textDecoration': 'underline',
      'cursor': 'pointer'
    },
  },

  urlBoletoSpanSuccess: {
    fontWeight: 'bold',
    backgroundColor: '#EDF7ED',
    borderRadius: '20px',
    padding: '15px',
  },

  urlBoletoSpanError: {
    fontWeight: 'bold',
    backgroundColor: '#FCEDED',
    borderRadius: '20px',
    padding: '15px',
  },

  urlBoletoError: {
    fontWeight: 'bold',
    backgroundColor: '#FCEDED',
    borderRadius: '20px',
    padding: '15px',
  },

  footer_basic: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff'
  },
}));

export const useStylesPagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
