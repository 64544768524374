import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ModalSubSellers from './components/ModalSubSellers';

function SubSellerSelect({
  color = 'inherit',
  onChange
}) {
  const user = useSelector(({ User }) => User);

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [currentSeller, setCurrentSeller] = useState(user?.subsellerId);

  const handleSelectSubSeller = (subseller) => {
    setLoading(true);
    onChange(subseller?.id);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const findSeller = user?.subsellers?.find(seller => seller.id === user?.subsellerId);
    if (findSeller) {
      setCurrentSeller(`${findSeller?.name} - ${findSeller?.document}`);
      setLoading(false);
    }
  }, [user]);

  return (
    <div>
      {currentSeller && (
        <Button
          variant="outlined"
          size="small"
          color={color}
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleOpenModal}
        >
          {loading ? <CircularProgress size={15} /> : currentSeller}
        </Button>
      )}

      <ModalSubSellers open={openModal} onClose={handleCloseModal} onChange={handleSelectSubSeller} />
    </div>
  );
}

export default SubSellerSelect;

SubSellerSelect.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
}
