//ResourceTypes
export const ReportsActionTypes = {
  ASYNC_REPORTS_LIST: '@reports/ASYNC_REPORTS_LIST',
  CHANGE_REPORTS_LIST: '@reports/CHANGE_REPORTS_LIST',

  ASYNC_GENERATE_REPORT: '@reports/ASYNC_GENERATE_REPORT',
  LOADING_REPORT: '@reports/LOADING_REPORT',
  SUCCESS_REPORT: '@reports/SUCCESS_REPORT',

  ASYNC_REPORT_DOWNLOAD: '@reports/ASYNC_REPORT_DOWNLOAD',
};

const INITIAL_STATE = {
  totalReports: 0,
  countReports: 0,
  reports: [],
  isLoading: false,
  success: false
}

const ReportsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ReportsActionTypes.CHANGE_REPORTS_LIST:
      return {
        ...state,
        reports: payload.reports,
        totalReports: payload.total,
        countReports: payload.count
      };
    case ReportsActionTypes.LOADING_REPORT:
      return { ...state, isLoading: payload };
    case ReportsActionTypes.SUCCESS_REPORT:
      return { ...state, success: payload };
    default:
      return state;
  }
}

//Actions
export const asyncReportsList = (params) => ({
  type: ReportsActionTypes.ASYNC_REPORTS_LIST,
  payload: params
});

export const changeReportsList = (reports) => ({
  type: ReportsActionTypes.CHANGE_REPORTS_LIST,
  payload: reports
});

export const asyncReportsGenerate = (params) => ({
  type: ReportsActionTypes.ASYNC_GENERATE_REPORT,
  payload: params
});

export const loadingReports = (loading) => ({
  type: ReportsActionTypes.LOADING_REPORT,
  payload: loading
});

export const successReports = (success) => ({
  type: ReportsActionTypes.SUCCESS_REPORT,
  payload: success
});

export const asyncReportDownload = (reportID) => ({
  type: ReportsActionTypes.ASYNC_REPORT_DOWNLOAD,
  payload: reportID
});

export default ReportsReducer;
