import { toast } from "react-toastify";

const Notification = (type, message, onClose = () => { }) => {
  toast[type](message, {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    onClose,
  });
};

export default Notification;
