import { defaultApi } from '../services/api'

const baseHeaders = (othersHeaders = {}) => ({
  headers: {
    "Content-Type": "application/json",
    ...othersHeaders
  }
});

export const Get = (url, params, headers) => {
  return defaultApi.get(url, { params, ...baseHeaders(headers) })
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Post = (url, data, headers) => {
  return defaultApi.post(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Put = (url, data, headers) => {
  return defaultApi.put(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Delete = (url, data, headers) => {
  return defaultApi.delete(url, { data, headers: baseHeaders(headers).headers })
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Patch = (url, data, headers) => {
  return defaultApi.patch(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};
