import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import {all, takeEvery, put, call, takeLatest} from 'redux-saga/effects';
import { changeBalance, changeBalanceLoading, changeEntriesData, changeLoading, changeWithdrawLoading, FinancesActionTypes, asyncBalance } from 'store/reducers/finances.reducer';
import handleError from 'utils/handleError';
import Notification from 'utils/notifications';
import { getEntries, requestAllWithdraw, getBalance } from 'utils/web-api';

function* entriesData() {
  try {
    yield put(changeLoading(true));
    const response = yield call(getEntries);
    yield put(changeEntriesData(response));
  } catch (error) {
    handleError(error);
  } finally {
    yield put(changeLoading(false));
  }
}

function* balance({payload}) {
  try {
    yield put(changeBalanceLoading(true));
    const response = yield call(getBalance, payload);
    yield put(changeBalance(response));
  } catch (error) {
    handleError(error);
  } finally {
    yield put(changeBalanceLoading(false));
  }
}

function* allWithdraw() {
  try {
    yield put(changeWithdrawLoading(true));
    const response = yield call(requestAllWithdraw);
    yield put(asyncBalance());
    Notification('success', `Um saque de R$ ${formatCurrency(response.amount / 100)} foi solicitado!`);
  } catch (error) {
    if (error.response.status === 455) {
        Notification('error', 'Saldo insuficiente para saque.');
    } else {
        Notification('error', 'Erro ao realizar a solicitação de saque, tente novamente em instantes.');
    }
  } finally {
    yield put(changeWithdrawLoading(false));
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(FinancesActionTypes.ASYNC_ENTRIES_DATA, entriesData),
    yield takeEvery(FinancesActionTypes.ASYNC_BALANCE, balance),
    yield takeLatest(FinancesActionTypes.ASYNC_ALL_WITHDRAW, allWithdraw),
  ]);
}
