import React from 'react'
import ContentLoader from 'react-content-loader'

const TableLoader = props => (
  <ContentLoader
    width="100%"
    height="100%"
    viewBox="0 0 906 406"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="906" height="17" />

    <circle cx="820" cy="76" r="11" />
    <circle cx="854" cy="76" r="11" />
    <rect x="44" y="69" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="69" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="69" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="101" rx="3" ry="3" width="897" height="2" />

    <circle cx="820" cy="128" r="11" />
    <circle cx="854" cy="128" r="11" />
    <rect x="44" y="120" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="120" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="120" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="152" rx="3" ry="3" width="897" height="2" />

    <circle cx="820" cy="178" r="11" />
    <circle cx="854" cy="178" r="11" />
    <rect x="44" y="170" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="170" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="170" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="202" rx="3" ry="3" width="897" height="2" />

    <circle cx="820" cy="228" r="11" />
    <circle cx="854" cy="228" r="11" />
    <rect x="44" y="221" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="221" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="221" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="252" rx="3" ry="3" width="897" height="2" />

    <circle cx="820" cy="278" r="11" />
    <circle cx="854" cy="278" r="11" />
    <rect x="44" y="271" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="271" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="271" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="303" rx="3" ry="3" width="897" height="2" />

    <circle cx="820" cy="329" r="11" />
    <circle cx="854" cy="329" r="11" />
    <rect x="44" y="322" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="322" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="322" rx="3" ry="3" width="141" height="15" />
    <rect x="4" y="354" rx="3" ry="3" width="897" height="2" />

    <rect x="0" y="0" rx="3" ry="3" width="2" height="406" />
    <rect x="905" y="0" rx="3" ry="3" width="2" height="406" />

    <circle cx="820" cy="380" r="11" />
    <circle cx="854" cy="380" r="11" />
    <rect x="44" y="373" rx="3" ry="3" width="141" height="15" />
    <rect x="244" y="373" rx="3" ry="3" width="299" height="15" />
    <rect x="600" y="373" rx="3" ry="3" width="141" height="15" />
    <rect x="0" y="404" rx="3" ry="3" width="905" height="2" />

    <rect x="0" y="35" rx="3" ry="3" width="905" height="17" />
    <rect x="0" y="10" rx="3" ry="3" width="42" height="33" />

    <rect x="184" y="10" rx="3" ry="3" width="60" height="33" />
    <rect x="540" y="10" rx="3" ry="3" width="58" height="33" />
    <rect x="740" y="10" rx="3" ry="3" width="66" height="33" />
    <rect x="872" y="10" rx="3" ry="3" width="35" height="33" />
  </ContentLoader>
);

export default TableLoader;
