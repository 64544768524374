import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 300;

export const useStylesHeader = makeStyles((theme) => ({
  header_top: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    width: '100%',
    height: '60px',
    left: '40px',
    padding: "0 40px",
  },
  leftSpace: {
    left: 0,
    padding: '0 10px',
  },
  nickname: {
    color: "#535758",
    fontWeight: 600,
    marginRight: '10px',
    textAlign: 'right',
  },
  subsellerIdDesc: {
    fontSize: '13px'
  },
  buttonOpenMenu: {
    position: 'absolute',
    top: '40px',
    left: '46px',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: '#99bd4c',
    border: '6px solid #F4F7FF',
    color: '#fff',
    cursor: 'pointer',
  },
  header_left: {
    position: "fixed",
    width: '180px',
    height: '100vh',
    top: 0,
    zIndex: 99,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  appBar: {
    backgroundColor: '#F4F7FF',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none !important',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#fff',
    borderRight: 'none',
    boxShadow: '0px 2px 4px -1px rgba(201,204,214,0.4)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 60,
    backgroundColor: '#fff',
    borderRight: 'none',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logoClose: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '65px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },

  icon_router: {
    fontSize: 28,
    cursor: 'pointer',
  },
  content_logout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon_more: {
    cursor: 'pointer',

    '&:hover': {
      color: '#00619e'
    }
  },
  container: {
    position: "relative",
    width: 'calc(100% - 65px)',
    top: 60,
    left: '65px',
    [theme.breakpoints.down(820)]: {
      width: '100%',
      height: 'calc(100vh - 60px)',
      left: 0,
      top: 60,
    }
  },
  containerOpen: {
    width: 'calc(100% - 240px)',
    left: '240px'
  },
  header_logout_mobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '95%'
  }
}));
