
export const routes = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  RECOVER_PASSWORD: '/recover-password',
  RESET_PASSWORD: '/reset-password',
  SALES_REPORTS: '/sales-reports',
  FINANCIAL_AGENDA: '/financial-agenda',
  DASHBOARD: '/dashboard',
  ALL_REPORTS: '/reports',
  CHANGE_PASSWORD: '/change-password',
  FINANCES: '/finances',
  USERS_MANAGEMENT: '/users-management',
  SUBSELLERS: '/subsellers',
  SCHEDULES: '/schedules',
  PAYMENTPLAN: '/payment-plan',
  READPLAN: '/payment-plan/:id'
}
