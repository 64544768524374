import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import TableCustomPagination from "./TableCustomPagination";

import { useStylesTable } from "./styles/styles";
import { parse } from "date-fns";
import { compareDate } from "utils/functions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const TableCustom = ({
  columns,
  rows,
  action = false,
  currentPage,
  onCurrentPage,
  data = [],
  count,
  onPageSize,
  pageSize,
  rowsPerPageOptions,
  selectRow = false,
  activePages = true,
  basicPagination = false,
  onClickDetails,
  onClickRead,
  actionButton,
  actionLoading = false,
  readButton = false,
  actionIdButton,
  setSelectRow = {},
  tableClassName,
  sortable = false,
  error = false,
  expandedId = null,
  expandedUrl = false,
  handleExpandedRow,
  haveBoletos = false,
  onSort,
}) => {
  const classes = useStylesTable();
  const [page, setPage] = useState(currentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize || 25);

  useEffect(() => {
    if (onCurrentPage) onCurrentPage(page);
  }, [page, onCurrentPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    onSort(event, property);
  };

  function ExpandedRow(row) {
    const boletos = data.find((item) => item.id === row?.row?.id);
    const statusBoleto = row?.row?.status?.props?.children;
    return (
      <TableRow>
        <TableCell colSpan={columns?.length + 2}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            className={classes.urlContainer}
          >
            <span className={statusBoleto === 'Boleto Gerado' ? classes.urlBoletoSpanSuccess : classes.urlBoletoSpanError}>
              URL do boleto:{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={boletos?.boleto?.url}
                style={{color: statusBoleto === 'Boleto Substituído' && '#EC5350'}}
                className={classes.urlBoletoSuccess}
              >
                {boletos.boleto.url}
              </a>
            </span>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const handleColorBoleto = (row, error) => {
    const status = row?.status?.props?.children;
    const statusData = row?.expirationDate && compareDate(row?.expirationDate);

    if (status === 'Boleto Substituído') {
      return '#ccc'
    }

    if (status === 'Boleto Gerado' && statusData) {
      return '#ccc'
    }

    if (status === 'Boleto Gerado' && !statusData) {
      return '#2E7C33'
    }

    if (status === 'Boleto Pago') {
      return '#2E7C33'
    }

    if (status === 'Cancelado') {
      return '#ccc'
    }

    if (error) {
      return '#EC5350';
    }

    if (row) {
      return '#00619E';
    }
  }

  const handleStatusBoleto = (row) => {
    const statusBoleto = row?.status?.props?.children;
    const statusData = row?.expirationDate && compareDate(row?.expirationDate);
    if (row.disabledAction || (row.id === actionIdButton && actionLoading)
    || (statusBoleto === "Boleto Substituído" || statusBoleto === 'Pagamento Aprovado')) {
      return true;
    }

    if (statusBoleto === 'Cancelado') {
      return true;
    }

    if (statusBoleto === 'Boleto Gerado' && statusData) {
      return true;
    }

    if (statusBoleto === 'Boleto Gerado' && !statusData) {
      return false;
    }

    if (statusBoleto === 'Boleto Pago' || statusData) {
      return true;
    }
  }

  const handleClickDetails = (row) => {
    onClickDetails(row);

    const formatExpirationDate = parse(row?.expirationDate, "dd/MM/yyyy 'às' HH:mm'h'", new Date());
    const newRow = {
      ...row,
      expirationDate: formatExpirationDate
    }
    selectRow && setSelectRow(newRow);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={`${classes.container} ${tableClassName}`}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align ?? "inherit"}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                  sortDirection={column.orderBy ?? false}
                >
                  {sortable && column.orderable !== false ? (
                    <TableSortLabel
                      active={column.orderBy ? true : false}
                      direction={column.orderBy ?? "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    <>{column.label}</>
                  )}
                </TableCell>
              ))}
              {action ? (
                <TableCell className={classes.head} style={{ minWidth: 170 }} />
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              const IDBoleto = row?.id;
              return (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      return (
                        <CellRender
                          key={column.id}
                          row={row}
                          column={column}
                          align={column.align}
                        />
                      );
                    })}

                    {readButton ? (
                      <TableCell align="center">
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          disabled={
                            row.disabledAction ||
                            (row.id === actionIdButton && actionLoading)
                          }
                          onClick={() => onClickRead(row)}
                        >
                          {readButton}
                        </Button>
                      </TableCell>
                    ) : null}

                    {action ? (
                      <TableCell align="center">
                        <Button
                          size="small"
                          color="error"
                          variant="contained"
                          className={classes.buttonAction}
                          disabled={handleStatusBoleto(row)}
                          onClick={() => handleClickDetails(row)}
                          style={{
                            backgroundColor: handleColorBoleto(row, error)
                          }}
                        >
                          {row.id === actionIdButton && actionLoading
                            ? "Carregando..."
                            : actionButton}
                        </Button>
                      </TableCell>
                    ) : null}
                    {haveBoletos && <TableCell>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                      <div style={{marginRight: '20px'}}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleExpandedRow(row)}
                        >
                          {expandedUrl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                      </div>
                    </div>
                    </TableCell>}
                  </TableRow>
                  {expandedId === IDBoleto && expandedUrl && <ExpandedRow row={row} />}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {activePages ? (
        !basicPagination ? (
          <TablePagination
            className={classes.footer}
            rowsPerPageOptions={rowsPerPageOptions || []}
            labelRowsPerPage="Itens por página:"
            component="section"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TableCustomPagination}
          />
        ) : (
          <div className={classes.footer_basic}>
            <Pagination
              shape="rounded"
              color="primary"
              page={page}
              count={count}
              onChange={handleChangePage}
            />
          </div>
        )
      ) : null}
    </Paper>
  );
};

const CellRender = ({ column, row, align }) => {
  if (column.Cell) {
    return <TableCell align={align ?? "inherit"}>{column.Cell(row)}</TableCell>;
  }

  return (
    <TableCell align={align ?? "inherit"}>
      {column?.format ? column.format(row[column.id], row) : row[column.id]}
    </TableCell>
  );
};

export default TableCustom;
