import Notification from "./notifications";

const handleError = (mensage = undefined) => {
  let msg = mensage || "Ocorreu um erro inesperado, por favor tente novamente.";
  Notification("error", msg);
};

export const handleSuccess = (msg = "Operação realizada com sucesso") => {
  Notification("success", msg);
};

export const handleWarning = (msg = "Operação realizada com sucesso") => {
  Notification("warn", msg);
};

export default handleError;
