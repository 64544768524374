import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'utils/routers.constants';
import Route from './Route';
import { getUserInfo, getSubSellers } from 'store/reducers/user.reducer';
import { getAvailableUsers } from 'store/reducers/subseller.reducer.js';
import PaymentPlan from 'views/PaymentPlan/PaymentPlan';
import ReadPlan from 'views/PaymentPlan/ReadPlan';

const SignIn = lazy(() => import('views/SignIn/SignIn'));
const RecoverPassword = lazy(() => import('views/RecoverPassword/RecoverPassword'));
const ResetPassword = lazy(() => import('views/ResetPassword/ResetPassword'));
const SalesReports = lazy(() => import('views/SalesReports/SalesReports'));
const FinancialAgenda = lazy(() => import('views/FinancialAgenda/FinancialAgenda'));
const AllReports = lazy(() => import('views/ReceivablesReports/ReceivablesReports'));
const ChangePassword = lazy(() => import('views/ChangePassword/ChangePassword'));
const Finances = lazy(() => import('views/Finances/Finances'));
const UsersManagement = lazy(() => import ('views/UsersManagement/UsersManagement'));
const Subsellers = lazy(() => import ('views/Subsellers/Subsellers'));
const Schedules = lazy(() => import ('views/Schedules/Schedules'));

const Routes = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector(({ User }) => User);
  const { loggedIn } = useSelector(({ Auth }) => Auth);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUserInfo());
      dispatch(getSubSellers());
      if (admin) {
        dispatch(getAvailableUsers());
      }
    }
  }, [loggedIn, admin, dispatch]);

  return (
    <Suspense fallback="">
      <Switch>
        <Route path={routes.SIGN_IN} component={SignIn} exact />
        <Route path={routes.RECOVER_PASSWORD} component={RecoverPassword} />
        <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
        <Route isPrivate path={routes.SALES_REPORTS} component={SalesReports} exact />
        <Route isPrivate path={routes.FINANCIAL_AGENDA} component={FinancialAgenda} exact />
        <Route isPrivate path={routes.ALL_REPORTS} component={AllReports} exact />
        {admin ? <Route isPrivate path={routes.USERS_MANAGEMENT} component={UsersManagement} exact></Route> : '' }
        {admin ? <Route isPrivate path={routes.SUBSELLERS} component={Subsellers} exact></Route> : '' }
        <Route isPrivate path={routes.CHANGE_PASSWORD} component={ChangePassword} exact />
        <Route isPrivate path={routes.FINANCES} component={Finances} exact />
        <Route isPrivate path={routes.SCHEDULES} component={Schedules} exact />
        <Route isPrivate path={routes.PAYMENTPLAN} component={PaymentPlan} exact />
        <Route isPrivate path={routes.READPLAN} component={ReadPlan} exact />

        <Redirect from="*" to={loggedIn ? routes.FINANCIAL_AGENDA : routes.SIGN_IN} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
