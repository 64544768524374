//Type
export const UserActionTypes = {
  ASYNC_GET_USER_INFO: '@user/ASYNC_GET_USER_INFO',
  SET_USER_INFO: '@user/SET_USER_INFO',
  CLEAR_USER_INFO: '@user/CLEAR_USER_INFO',
  ASYNC_GET_SUBSELLERS: '@user/ASYNC_GET_SUBSELLERS',
  SET_SUBSELLERS: '@user/SET_SUBSELLERS',
  ASYNC_CHANGE_SUBSELLER: '@user/ASYNC_CHANGE_SUBSELLER',
  SET_SUBSELLER_ID: '@user/SET_SUBSELLER_ID',
  GET_USERS_LIST: '@user/GET_USERS_LIST',
  SET_USERS_LIST: '@user/SET_USERS_LIST',
  CREATE_NEW_USERS: '@user/CREATE_NEW_USERS',
  POST_NEW_USERS: '@user/POST_NEW_USERS',
  SET_LOADER_STATE: '@user/SET_LOADER_STATE',
  GET_USER_BY_ID: '@user/GET_USER_BY_ID',
  SET_USER_BY_ID: '@user/SET_USER_BY_ID',
  UPDATE_USER:  '@user/UPDATE_USER',
  SET_USER:  '@user/SET_USER',
  LOADING_INFO_BY_USER: '@user/LOADING_INFO_BY_USER',
  LOADING_USERS_LIST: '@user/LOADING_USERS_LIST'
}

const INITIAL_STATE = {
  id: '',
  username: '',
  name: '',
  phoneNumber: '',
  roles: '',
  status: '',
  subsellerId: '',
  admin: false,
  subsellers: [],
  users: {},
  loading: true,
  success: false,
  newUser: {},
  userId: '',
  selectUser: {},
  dataUser: {},
  totalPages: 0,
  loadingInfoByUser: false,
}

export const userReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UserActionTypes.SET_USER_INFO:
      return {
        ...state,
        id: payload.id,
        username: payload.username,
        name: payload.name,
        phoneNumber: payload.phoneNumber,
        roles: payload.roles,
        status: payload.status,
        subsellerId: payload.subsellerId,
        admin: payload.admin,
      };

    case UserActionTypes.CLEAR_USER_INFO:
      return {
        ...state,
        id: '',
        username: '',
        name: '',
        phoneNumber: '',
        roles: '',
        status: '',
        subsellerId: '',
        admin: false,
        subsellers: [],
      };

    case UserActionTypes.SET_SUBSELLERS:
      return {
        ...state,
        subsellers: payload,
      };

    case UserActionTypes.SET_SUBSELLER_ID:
      return {
        ...state,
        subsellerId: payload,
      };
    case UserActionTypes.GET_USERS_LIST:
      return {
        ...state,
        success: false,
      }
    case UserActionTypes.LOADING_USERS_LIST:
      return {
        ...state,
        loading: true,
      }
    case UserActionTypes.SET_USERS_LIST:
      return {
        ...state,
        users: payload,
        loading: false,
        totalPages: Math.ceil(payload.total / payload.page_size),
      };
    case UserActionTypes.POST_NEW_USERS:
      return {
        ...state,
        newUser: payload,
        success: true,
      }
    case UserActionTypes.GET_USER_BY_ID:
      return {
        ...state,
        userId: payload
      }
    case UserActionTypes.SET_USER_BY_ID:
      return {
        ...state,
        selectUser: payload,
      }
    case UserActionTypes.LOADING_INFO_BY_USER:
      return {
        ...state,
        loadingInfoByUser: payload,
      }
    case UserActionTypes.UPDATE_USER:
      return {
        ...state,
        dataUser: payload,
      }  
    case UserActionTypes.SET_USER:
      return {
        ...state,
        dataUser: payload,
        success: true,
      }  
    default:
      return state;
  }
};

export const getUserInfo = () => ({
  type: UserActionTypes.ASYNC_GET_USER_INFO,
});

export const setUserInfo = payload => ({
  type: UserActionTypes.SET_USER_INFO,
  payload
});

export const getSubSellers = () => ({
  type: UserActionTypes.ASYNC_GET_SUBSELLERS,
});

export const setSubSellers = payload => ({
  type: UserActionTypes.SET_SUBSELLERS,
  payload
});

export const clearUserInfo = () => ({
  type: UserActionTypes.CLEAR_USER_INFO,
});

export const changeSubSeller = payload => ({
  type: UserActionTypes.ASYNC_CHANGE_SUBSELLER,
  payload,
});

export const changeSubSellerId = payload => ({
  type: UserActionTypes.SET_SUBSELLER_ID,
  payload,
});

export const getUsersList = (params) => ({
  type: UserActionTypes.GET_USERS_LIST,
  payload: params
});

export const loadingUsersList = payload =>({
  type: UserActionTypes.LOADING_USERS_LIST,
  payload: payload,
})
export const setUsersList = (users) => ({
  type: UserActionTypes.SET_USERS_LIST,
  payload: users,
})

export const createNewUsers = (newUser) => ({
  type: UserActionTypes.CREATE_NEW_USERS,
  payload: newUser,
})

export const postNewUsers = (newUser) => ({
  type: UserActionTypes.POST_NEW_USERS,
  payload: newUser,
})

export const getUserById = (selectUser) => ({
  type: UserActionTypes.GET_USER_BY_ID,
  payload: selectUser
})

export const setUserById = (selectUser) => ({
  type: UserActionTypes.SET_USER_BY_ID,
  payload: selectUser
})

export const loadingInfoByUser = (loading) => ({
  type: UserActionTypes.LOADING_INFO_BY_USER,
  payload: loading
})

export const updateUser = (dataUser) => ({
  type: UserActionTypes.UPDATE_USER,
  payload: dataUser,
})

export const setUser = (dataUser) => ({
  type: UserActionTypes.SET_USER,
  payload: dataUser,
})

export default userReducer;
