import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Header from 'components/Header/Header';
import { routes } from 'utils/routers.constants';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  notFound,
  ...rest
}) {
  const { loggedIn } = useSelector(state => state.Auth);

  if (!loggedIn && isPrivate) {
    return <Redirect to={routes.SIGN_IN} />;
  }

  if (loggedIn && !isPrivate && !notFound) {
    return <Redirect to={routes.FINANCIAL_AGENDA} />;
  }

  return (
    <>
      {isPrivate ? (
        <Header currentRoute={rest.path}>
          <Route
            {...rest}
            render={props => (
              <Component {...props} />
            )}
          />
        </Header>
      ) : (
          <Route
            {...rest}
            render={props => (
              <Component {...props} />
            )}
          />
        )}
    </>
  );
}

RouteWrapper.defaultProps = { isPrivate: false };
