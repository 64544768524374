import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import localStorage from 'redux-persist/lib/storage';

import Auth from './auth.reducer';
import User from './user.reducer';
import Loading from './loading.reducer';
import Schedule from './schedule.reducer';
import Transaction from './transaction.reducer';
import Reports from './reports.reducer';
import Finances from './finances.reducer';
import Subseller from './subseller.reducer.js';
import PaymentPlan from './paymentPlan.reducer.js';

const AppReducer = (history) => combineReducers({
  router: connectRouter(history),
  Auth,
  User,
  Loading,
  Schedule,
  Transaction,
  Reports,
  Finances,
  Subseller,
  PaymentPlan,
});

const RootReducer = (history) => (state, action) => {
  if (action.type === '@auth/LOGOUT') {
    const { router } = state
    localStorage.removeItem('persist:cispay-backoffice')
    state = { router }
  }
  return AppReducer(history)(state, action)
}

export default RootReducer;
