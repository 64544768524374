import axios from 'axios';
import { urls } from "utils/urls.constants";
import { store } from 'store/store-config';
import { logout } from 'store/reducers/auth.reducer';
import { clearUserInfo } from 'store/reducers/user.reducer';

const api = axios.create({
  baseURL: urls.SCHEDULE,
});

export const defaultApi = axios.create({
  baseURL: '',
  timeout: 600000,

});

export const loginApi = axios.create({
  baseURL: urls.LOGIN,
  timeout: 600000,
});

defaultApi.interceptors.response.use(async (config) => {
  return config;
}, async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch(clearUserInfo());
    store.dispatch(logout());
  }
  return Promise.reject(error);
});


export default api;
