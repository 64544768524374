export const PaymentPlanActionTypes = {
    ASYNC_PAYMENT_PLANS: '@auth/ASYNC_PAYMENT_PLANS',
    GET_PAYMENT_PLANS: '@auth/GET_PAYMENT_PLANS',
    LOADING: '@auth/LOADING',
    ASYNC_PLAN: '@auth/PLAN',
    SET_PLAN: '@auth/GET_PLAN',
    GET_BOLETOS: '@auth/GET_BOLETOS',
    ASYNC_EXCHANGE_BOLETO: '@auth/ASYNC_EXCHANGE_BOLETO',
    LOADING_BOLETO_EXCHANGE: '@auth/LOADING_BOLETO_EXCHANGE',
    LOADING_DISABLE_PLAN: '@auth/LOADING_DISABLE_PLAN',
    ASYNC_DISABLE_PLAN: '@auth/ASYNC_DISABLE_PLAN',
}

const INITIAL_STATE = {
    paymentPlans: [],
    plans: [],
    plan: {},
    loading: true,
    boletos: [],
    loadingBoletoExchange: false,
    loadingDisablePlan: false,
};

export const PaymentPlan = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
      case PaymentPlanActionTypes.GET_PAYMENT_PLANS:
        return { ...state, plans: payload }
      case PaymentPlanActionTypes.LOADING:
        return { ...state, loading: payload }
      case PaymentPlanActionTypes.SET_PLAN:
        return { ...state, plan: payload }
      case PaymentPlanActionTypes.GET_BOLETOS:
        return { ...state, boletos: payload }
      case PaymentPlanActionTypes.LOADING_BOLETO_EXCHANGE:
        return { ...state, loadingBoletoExchange: payload }
      case PaymentPlanActionTypes.LOADING_DISABLE_PLAN:
        return { ...state, loadingDisablePlan: payload }
      default:
        return state;
    }
};

export const asyncPaymentPlans = (payload) => ({
    type: PaymentPlanActionTypes.ASYNC_PAYMENT_PLANS,
    payload
});

export const asyncPlan = (payload) => ({
  type: PaymentPlanActionTypes.ASYNC_PLAN,
  id: payload
});

export const setPlan = (payload) => ({
  type: PaymentPlanActionTypes.SET_PLAN,
  payload: payload
});

export const setBoletos = (payload) => ({
  type: PaymentPlanActionTypes.GET_BOLETOS,
  payload: payload
});

export const setPlans = (payload) => ({
    type: PaymentPlanActionTypes.GET_PAYMENT_PLANS,
    payload
});

export const setLoading = (payload) => ({
  type: PaymentPlanActionTypes.LOADING,
  payload
});

export const asyncExchangeBoleto = (payload) => ({
  type: PaymentPlanActionTypes.ASYNC_EXCHANGE_BOLETO,
  payload
});

export const setLoadingDisablePlan = (payload) => ({
  type: PaymentPlanActionTypes.LOADING_DISABLE_PLAN,
  payload
});

export const setLoadingBoletoExchange = (payload) => ({
  type: PaymentPlanActionTypes.LOADING_BOLETO_EXCHANGE,
  payload
});

export const asyncDisablePlan = (payload) => ({
  type: PaymentPlanActionTypes.ASYNC_DISABLE_PLAN,
  payload
});

export default PaymentPlan;
