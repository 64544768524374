//ResourceTypes
export const ScheduleActionTypes = {
  ASYNC_SCHEDULE_BALANCE: '@schedule/ASYNC_SCHEDULE_BALANCE',
  CHANGE_SCHEDULE_BALANCE: '@schedule/CHANGE_SCHEDULE_BALANCE',
  ASYNC_SCHEDULE_EVENTS: '@schedule/ASYNC_SCHEDULE_EVENTS',
  CHANGE_SCHEDULE_EVENTS: '@schedule/CHANGE_SCHEDULE_EVENTS',
  ASYNC_SCHEDULE_TRANSACTIONS: '@schedule/ASYNC_SCHEDULE_TRANSACTIONS',
  CHANGE_SCHEDULE_TRANSACTIONS: '@schedule/CHANGE_SCHEDULE_TRANSACTIONS',
};

const INITIAL_STATE = {
  balance: 0,
  totalBalance: 0,
  payments: [],
  totalPages: 0,
  data: [],
  isLoadingBalance: true,
  isLoadingEvents: true,
}

const scheduleReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ScheduleActionTypes.CHANGE_SCHEDULE_BALANCE:
      return { ...state, balance: payload.balance, isLoadingBalance: false };
    case ScheduleActionTypes.CHANGE_SCHEDULE_EVENTS:
      return { ...state, totalBalance: payload.balance, isLoadingEvents: false }
    case ScheduleActionTypes.CHANGE_SCHEDULE_TRANSACTIONS:
      return { ...state, totalPages: payload.totalPages, data: payload.transactions }
    default:
      return state;
  }
}

//Actions
export const asyncScheduleBalance = () => ({
  type: ScheduleActionTypes.ASYNC_SCHEDULE_BALANCE,
});

export const changeScheduleBalance = (balance) => ({
  type: ScheduleActionTypes.CHANGE_SCHEDULE_BALANCE,
  payload: balance
});

export const asyncScheduleEvents = (params) => ({
  type: ScheduleActionTypes.ASYNC_SCHEDULE_EVENTS,
  payload: params
});

export const changeScheduleEvents = (values) => ({
  type: ScheduleActionTypes.CHANGE_SCHEDULE_EVENTS,
  payload: values
});

export const asyncScheduleTransactions = (params) => ({
  type: ScheduleActionTypes.ASYNC_SCHEDULE_TRANSACTIONS,
  payload: params
});

export const changeScheduleTransactions = (values) => ({
  type: ScheduleActionTypes.CHANGE_SCHEDULE_TRANSACTIONS,
  payload: values
});

export default scheduleReducer;
