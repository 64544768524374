import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight, ExitToApp, Lock } from '@material-ui/icons';
import {
  Typography,
  Icon,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';

import { Icons } from 'components';
import { DataHeader } from './DataHeader';
import useWidth from 'hooks/useWidth';
import MenuMobile from './MenuMobile';
import SubSellerSelect from './SubSellerSelect';
import breadcrumbsFunc from './breadcrumbs-function';
import { routes } from 'utils/routers.constants';

import { history } from 'store/store-config';
import { logout } from 'store/reducers/auth.reducer';
import { changeSubSeller } from 'store/reducers/user.reducer';

import { StyledMenu, StyledMenuItem } from "./styles/customMenu";
import { useStylesHeader } from "./styles/styles";

const Header = ({ children, currentRoute }) => {
  const dispatch = useDispatch();
  const classes = useStylesHeader();
  const displayWidth = useWidth();
  const theme = useTheme();
  const { pathname } = useLocation();
  const definedWidth = 820;

  const user = useSelector(({ User }) => User);

  const [open, setOpen] = useState(false);
  const [, setBreadcrumbs] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const items = breadcrumbsFunc(currentRoute);
    setBreadcrumbs(items);
  }, [currentRoute]);

  const handleLogout = () => {
    dispatch(logout());
  }

  const handleLinkRouter = useCallback((router) => {
    history.push(router);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangePassword = useCallback(() => {
    history.push(routes.CHANGE_PASSWORD);
    handleClose();
  }, [handleClose]);

  const handleChangeSubSeller = subsellerId => {
    dispatch(changeSubSeller(subsellerId));
  };

  return (
    <>
      {displayWidth > definedWidth ? (
        <>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <div className={clsx(classes.logoClose, { [classes.hide]: open })}>
                <Icons name='icon-cispay' color="#00619E" width={35} height={35} />
              </div>

              <button type="button" className={clsx(classes.buttonOpenMenu, {
                [classes.hide]: open,
              })} onClick={handleDrawerOpen}>
                <ChevronRight />
              </button>

              <section className={clsx(classes.header_top, { [classes.leftSpace]: open })}>
                <SubSellerSelect
                  color='primary'
                  onChange={handleChangeSubSeller}
                />

                <section className={classes.content_logout}>
                  <Typography variant='subtitle1' component='span' className={classes.nickname}>
                    {user.name}
                  </Typography>
                  <>
                    <IconButton size="medium" onClick={handleClick}>
                      <Icon className={classes.icon_more}>keyboard_arrow_down</Icon>
                    </IconButton>

                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <StyledMenuItem onClick={handleChangePassword}>
                        <ListItemIcon >
                          <Lock fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Trocar senha" />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                      </StyledMenuItem>
                    </StyledMenu>
                  </>
                </section>
              </section>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <Icons name='icon-logo-cispay' color="#00619E" width={100} height={40} />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>
            <List>
              {DataHeader.filter(x => user.admin ? true : x.admin === false).map((item) => (
                <div key={item.id}>
                  <Tooltip key={item.id} title={item.title} placement="right">
                    <ListItem button onClick={() => handleLinkRouter(item.router)} >
                      <ListItemIcon>
                        <Icon
                          className={classes.icon_router}
                          style={{ color: pathname === item.router ? '#00619E' : '#bbc3cc' }}
                        >
                          {item.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText primary={item.title} style={{ color: pathname === item.router ? '#00619E' : '#bbc3cc' }} />
                    </ListItem>
                  </Tooltip>
                </div>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
          <>
            <AppBar position="fixed" color='primary'>
              <Toolbar style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                <MenuMobile
                  Items={DataHeader.filter(x => user.admin ? true : x.admin === false)}
                  onLinkRouter={handleLinkRouter}
                  onLogout={handleLogout}
                />
                <SubSellerSelect
                  subsellers={user.subsellers}
                  selected={user.subsellerId}
                  onChange={handleChangeSubSeller}
                />
                <Icons name='icon-cispay' width={34} height={34} />
              </Toolbar>
            </AppBar>
          </>
        )
      }
        <div className={clsx(classes.container, { [classes.containerOpen]: open })}>
          {children}
        </div>
    </>
  );
}

export default Header;
