import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Container, Grid, Modal, Typography } from '@material-ui/core';
import { Table } from 'components';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { asyncDisablePlan, asyncPaymentPlans } from 'store/reducers/paymentPlan.reducer';
import { convertToCurrency, documentType, planStatus } from 'utils/functions';
import { routes } from 'utils/routers.constants';
import { useStyles } from 'views/Finances/styles/styles';
import TableLoader from 'views/SalesReports/components/skletons/TableLoader';

const PaymentPlan = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const { plans, loading } = useSelector((state) => state.PaymentPlan);
    const [open, setOpen] = useState(false);
    const [idPlan, setIdPlan] = useState();
    const {loadingDisablePlan} = useSelector(state => state.PaymentPlan);

    const columns = [
      { id: 'id', label: 'ID do Plano', minWidth: 170 },
      { id: 'status', label: 'Status', minWidth: 130 },
      { id: 'cispay_client', label: 'Cliente Cispay', minWidth: 170, },
      { id: 'price', label: 'Valor', minWidth: 170 },
      { id: 'created_at', label: 'Criado em', minWidth: 170 },
      { id: 'updated_at', label: 'Atualizado em', minWidth: 130 },
    ];

    const PaymentPlanFormated = useMemo(() => {
      if (plans) {
        return plans?.items?.map(plan => ({
          id: plan?.id,
          status: <div className={plan?.status === 'Canceled' ? classes.bgStatusError : classes.bgStatusPrimary}>
            {planStatus(plan?.status)}
          </div>,
          cispay_client: plan?.customer?.document?.type === documentType.CPF ? `${plan?.customer?.firstName} ${plan?.customer?.lastName}` :  plan?.customer?.corporateName ,
          price: convertToCurrency(plan?.paymentPlan?.price),
          created_at: format(parseISO(plan?.created_at), "dd/MM/yyyy 'às' HH:mm"),
          updated_at: format(parseISO(plan?.updated_at), "dd/MM/yyyy 'às' HH:mm"),
        }));
      }
      return [];
    }, [plans]); //eslint-disable-line

    const handleGetPaymentPlan = useCallback(() => {
      dispatch(asyncPaymentPlans({page, pageSize}));
    }, [dispatch, page, pageSize]);

    useEffect(() => {
      handleGetPaymentPlan();
    }, [handleGetPaymentPlan, page, pageSize]);

    const handleRead = (plan) => {
      const url = `${routes.READPLAN.replace(':id', plan.id)}`;
      history.push(url);
    }

    const handleDisablePlan = (id) => {
      setOpen(true);
      setIdPlan(id);
    }

    const funcDisablePlan = () => {
      const payload = {
        id: idPlan,
      }
      dispatch(asyncDisablePlan(payload))
      setOpen(false);
    }

    const handleCurrentPage = (page) => {
      setPage(page);
    };

    const handlePageSize = (pageSize) => {
      setPageSize(pageSize);
      setPage(0);
    };

    return (
      <Container className={classes.main} maxWidth="xl" component='main'>
        <Grid
          container
          component='header'
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Typography variant="h4" component="span" className={classes.title_header}>
            Planos de Pagamento
          </Typography>
        </Grid>

        {loading ? (
            <TableLoader />
          ) : (
          <Table
            action
            readButton = 'Visualizar'
            onClickRead = {handleRead}
            error={true}
            columns={columns}
            rows={PaymentPlanFormated}
            count={plans?.total}
            selectRow={false}
            currentPage={page}
            pageSize={pageSize}
            basicPagination={false}
            rowsPerPageOptions={[10, 25, 50]}
            actionButton="Desabilitar Plano"
            onCurrentPage={handleCurrentPage}
            onPageSize={handlePageSize}
            onClickDetails={handleDisablePlan}
          />
        )}

        <Modal
          open={open}
          onClose={() => {setOpen(false)}}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.disablePlan}>
            <h2 className={classes.disableTitle}>Tem certeza que deseja desabilitar este plano ?</h2>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="error"
                disabled={loadingDisablePlan}
                style={{marginRight: '10px', backgroundColor: loadingDisablePlan ? '#ccc' : '#EC5350', color: '#fff'}}
                onClick={() => funcDisablePlan()}
              >
                {loadingDisablePlan ? 'Carregando...' : 'Desabilitar'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {setOpen(loadingDisablePlan)}}
              >
                Voltar
              </Button>
            </div>
          </div>
        </Modal>
      </Container>
    )
  }

export default PaymentPlan;
