import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  },
  paper: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '12px'
  },
  header: {
    position: 'relative',
    width: '100%',
    height: '66px',
    padding: '20px',
    borderRadius: '10px',
    background: '#00619E',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  button_close: {
    position: 'absolute',
    top: '9px',
    right: '10px',
    color: '#fff',
    cursor: 'pointer',

    '&:hover': {
      color: '#f44336'
    },
  },
  section: {
    width: '100%',
    padding: '30px',
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },
  centered: {
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex_columns: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#cce2f5',
    padding: '10px 20px',
    marginBottom: '10px',
    borderRadius: '4px',
    borderBottom: '2px solid #929fc7',
  },
  flex_columns_row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',

    '& > strong': {
      textTransform: 'uppercase',
    }
  }
}));
