//Type
export const LoadingActionTypes = {
  LOADING_START: '@loading/LOADING_START',
  LOADING_STOP: '@loading/LOADING_STOP'
}

const INITIAL_STATE = {
  loading: false,
  loadingCounter: 0
};

export const loadingReducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case LoadingActionTypes.LOADING_START:
      return { ...state, loadingCounter: state.loadingCounter + 1, loading: true };
    case LoadingActionTypes.LOADING_STOP:
      if (state.loadingCounter > 1) {
        return { ...state, loadingCounter: state.loadingCounter - 1, loading: true };
      }
      return { ...state, loadingCounter: 0, loading: false };
    default:
      return state;
  }
};

export const loadingStart = () => ({
  type: LoadingActionTypes.LOADING_START
});

export const loadingStop = () => ({
  type: LoadingActionTypes.LOADING_STOP
});

export default loadingReducer;
