import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    padding: "20px 40px",
  },

  header: {
    flex: 1,
    marginBottom: "20px",
  },

  title_header: {
    color: "#535758",
    fontWeight: 500,
    fontSize: "calc(24px + (8/1200) * 100 * 1vw)",
    marginBottom: "20px",
  },

  loadingCircle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  inputText: {
    width: "100%",
    marginRight: "10px",
  },

  button: {
    width: '100%',
    display: 'flex',
    justifyContent:'flex-start'
  },

  container: {
    backgroundColor: "#fff",
    padding: "10px 30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  row: {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
  },

  subTitle: {
    textTransform: "uppercase",
    marginBottom: "20px",
    color: "#00619E",
  },

  newBoleto: {
    width: "500px",
    height: "300px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: 'column',
    backgroundColor: "#fff",
    border: '2px solid black',
    borderRadius: '25px'
  },

  cart: {
    width: '100%',
    height: '100%',
    marginBottom: '20px'
  },
}));
