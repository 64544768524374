import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import persistReducers from './persistReducer';
import RootReducer from './reducers/reducers';
import RootSaga from './sagas/sagas';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistReducers(RootReducer(history)),
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware
    ),
  ),
);

const persistor = persistStore(store);

sagaMiddleware.run(RootSaga);

export { history, store, persistor };
